@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: 'Montserrat', sans-serif!important;
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #000;
  --accent: #0049af;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
  height: 80px;
  background: white;
}

nav .link,
nav a {
  position: relative;
  color: var(--primary-color);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-inline: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-inline: 10px;
  border-radius: 5px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
}

.link:hover,
nav a:hover {
  color: var(--accent) !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.linkactive {
  color: var(--accent) !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.hide-800 {
  display: flex;
}

@media(max-width: 800px) {
  .hide-800 {
    display: none;
  }
}

.menu-button {
  position: fixed;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;

}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}
.mobile-nav a{
  text-decoration: none;
}
.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: white;
  font-size: 20px;
  font-weight: 800;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}

.top-section {
  background: url(mainback.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
}

.overlay-left {
  height: 100%;
  position: absolute;
  width: 40%;
  min-width: 500px;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.overlay-left h1 {
  font-size: 60px;
  font-weight: 700;
  color: #000;
  padding-left: 50px;
  margin-bottom: 0px;
}

.overlay-left h2 {
  padding-left: 55px;
  font-size: 16px;
  max-width: 400px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.span1 {
  color: #0049af;
}

.span2 {
  color: #4a81f4;
}

.span3 {
  color: #8cb1ef;
}

.dropdown {
  position: relativea;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  padding: 5px;
  z-index: 1;
  top: 25px;
  padding-top: 30px;
}

.dropdown-content .link,
.dropdown-content a {
  font-size: 14px;
  padding-inline: 0px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.how-it-works .container {
  width: 70%;
  min-width: 65rem;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}

.container .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 15%;
  height: 250px;
  position: relative;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 30%);

}
.greyback{
  border-radius: 10px;
  background: #fff;
  padding: 20px 10px;
}
.how-it-works h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 50px;
  color: white;
  position: relative;
}

.how-it-works {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  background: #f2f2f2;
}
.how-it-works-tophalf{
  width: 100%;
  background: #4a81f4;
  height: 400px;
  position: absolute;
  top: 0;
}

.item .image {
  width: 100px;
  height: 100px;
  padding: 20px;
  position: relative;
}
.number{
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin-inline: auto;
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #4a81f4;
  font-weight: 800;
  border: 2px solid #4a81f4;
  outline: 4px solid white;
}

.item img {
  width: 100%;
}


.container a {
  text-decoration: none;
  color: #000;
}

.item h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.why-us {
  background: #8cb0ef44;
  padding: 100px 0;
  width: 100%;
}

.why-us .inner {
  width: 80%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.why-us h1 {
  font-size: 50px;
}

.why-us p {
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  max-width: 600px;
  padding-left: 20px;
}

.why-us .right img {
  width: 50px;
}

.line {
  height: 300px;
  width: 2px;
  background: #0049af;
}

.why-us .right a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  gap: 10px;
}

footer {
  background: #f2f2f2;
}

.inner-footer {
  width: 90%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 0;
}

.inner-footer .left p {
  max-width: 600px;
  padding-left: 15px;
}

.inner-footer .right a {
  color: #000;
  font-weight: 500;
}

.products h1, .faq h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 50px;
}

.products {
  background: #fff;
  padding-top: 50px;
}

.product {
  width: 900px;
  height: 500px;
  margin-inline: auto;
  background: #f2f2f2;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.301);
  margin-bottom: 50px;
  display: flex;
  gap: 20px;
}

.product .left {
  width: 40%;
  height: 100%;
  background: #4a80f4be;
}

.product .left h1 {
  text-align: left;
  padding-left: 20px;
  padding-top: 50px;
  font-size: 30px;
  font-weight: 700;
}

.product .left p {
  padding-left: 20px;
  max-width: 90%;
  font-size: 14px;
  font-weight: 500;
}
.right ul{
  list-style: none;
  padding-inline: 30px;
}
.right ul li{
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.product{
  transition: all 0.1s ease-in-out;
}
.product:hover{
  transform: scale(1.02);
  background: #0049af;
  color: #fff;
  border-radius: 5px;
}

.product:hover .left{
  background: #000;
  border-radius: 5px;
}
.product:hover .right img{
 filter: invert(1);
}
.faq {
  width: 900px;
  margin-inline: auto;
  min-height: 80vh;
  padding-top: 50px;
  padding-bottom: 50px;
}
.MuiAccordionSummary-root .MuiTypography-root{
  font-size: 24px;
  font-weight: 500;
}
.MuiAccordion-root{
  border: 1px solid #0049af;
  border-radius: 5px!important;
  margin-bottom: 20px;
}
.MuiAccordionDetails-root .MuiTypography-root{
  font-style: italic;
}
.apply h1{
  font-size: 50px;
  text-align: center;
}
form{
  width: 700px;
  margin-inline: auto;
  margin-bottom: 100px;
  margin-top: 100px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.274);
  padding: 20px;
  border-radius: 10px;
}
.formsection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.formflex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
form h2{
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
label{
  font-size: 16px;
  font-weight: 500;
  margin-left:3px;
}
input{
  width: 300px;
  border-radius: 5px;
  height: 30px;
  border: 1px solid #0049af;
  padding-left: 10px;

  background: #e5eef5;
}
select{
  width: 315px;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #0049af;
  padding-left: 10px;
  background: #e5eef5;
}
.centerbut{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.sendbutton{
  width: 315px;
  border-radius: 10px;
  height: 40px;
  background: #0049af;
  color: white;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.1s all ease;
}
.sendbutton:hover{
  background: #000;
  border-radius: 5px;
}

@media(max-width: 1000px){
  .how-it-works .container{
    width: 90%;
    min-width: auto;
    flex-direction: column;
  }
  .container .item{
    width: 100%;
    margin-bottom: 30px;
  }
  .overlay-left{
    width: 100%;
    min-width: auto;
  }
  .item .image{
    width: 100px;
    height: 100px;
    padding: 30px;
    padding-bottom: 20px;
  }
  .how-it-works-tophalf{
    height: 300px;
  }
  .why-us .inner{
    flex-direction: column;
  }
.line{
  width: 100%;
  height: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.why-us h1, .how-it-works h1{
  font-size: 30px;
}
.why-us p, .why-us .right a{
  font-size: 20px;
}
.why-us .right img{
  width: 35px;
}
.inner-footer{
  flex-direction: column;
}
.inner-footer .left p{
  padding-left: 0px;
}
.overlay-left h1{
  font-size: 45px;
  letter-spacing: -2px;
  padding-left: 20px;
}
.overlay-left h2{
  padding-left: 25px;
  max-width: 70%;
}
.overlay-left{
  background: rgba(255,255,255,0.6);
}
.menu-button{
  display: flex;
  top: 25px;
}
.mainlogo{
  width: 150px;
  margin-top: 15px;
}
.product{
  width: 90%;
  height: auto;
  flex-direction: column;
}
.product .left{
  width: 100%;
}
.product .left h1{
  margin-bottom: 10px;
  padding-top: 10px;
}
.right{
  width: 90%;
  margin-inline: auto;
}
.right ul{
  padding-inline: 10px;
}
.faq{
  width: 90%;
}
form{
  width: 90%;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-inline: 0;
}
form h2{
  font-size: 18px;
}
.formflex{
  flex-direction: column;
  margin-bottom: 0;
}
.formsection{
  margin-top: 30px;
}
input{
  margin-bottom: 20px;
}
.greyback{
  padding: 5px;
}
.arrow{
  display: none;
}
.container{
  margin-bottom: 10px;
}
}
